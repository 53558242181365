import styled from 'styled-components';
import { redGradStart, redGradEnd } from 'constants/theme';

export const GradientLink = styled.a`
  /* white-space: nowrap; */
  background: linear-gradient(90deg, ${redGradStart}, ${redGradEnd} 85%);
  border-radius: 27px;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 1;
  padding: 1.55rem 4rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: box-shadow 0.2s ease-out;

  &:focus {
    outline: none;
    transform: scale(1.05);
  }
`;
